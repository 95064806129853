import { TimagesData } from '../components/cameras/Interfaces';
import { MapInterface } from '../components/map/Interfaces';
import { IWSData } from '../components/meteo/Interfaces';
import { PuidInterface } from '../components/puid/Interfaces';
import { TReportItem } from '../components/reports/Reports';
import { strDate } from '../components/utils/date';

type ServicesReturn<T> = {
  data?: T;
  error?: unknown | null;
};

type GetAllStations = {
  adms: boolean;
  puid: boolean;
  photo: boolean;
  map: boolean;
};

class Services {
  private async fetchUrl<T>(url: string): Promise<T> {
    const response = await fetch(url);
    if (!response.ok) throw new Error('Response not ok');
    return await response.json();
  }

  public getAllStations = async (
    props: GetAllStations,
  ): Promise<ServicesReturn<MapInterface>> => {
    const { adms, puid, photo, map } = props;
    try {
      const json = await this.fetchUrl<MapInterface>(
        `/data/stations?map=${map}&adms=${adms}&puid=${puid}&photo=${photo}`,
      );
      return { data: json, error: null };
    } catch (e) {
      return { error: e };
    }
  };

  public getWsById = async (id: number) => {
    try {
      const url = `/data/ws?id=${id}`;
      const json = await this.fetchUrl<IWSData>(url);
      return { data: json, error: null };
    } catch (e) {
      return { data: null, error: e };
    }
  };

  public getAtmById = async (id: number) => {
    try {
      const url = `/data/ws/atm?id=${id}`;
      const json = await this.fetchUrl<IWSData>(url);
      return { data: json, error: null };
    } catch (e) {
      return { data: null, error: e };
    }
  };

  public getWsByIdCharts = async <T>(
    id: number | undefined,
    { from, to }: { from: Date; to: Date },
  ): Promise<T> => {
    const fromStr = strDate(from);
    const toStr = strDate(to);
    const url = new URL('/data/ws/details', window.location.origin);
    id && url.searchParams.append('id', id.toString());
    url.searchParams.append('date', fromStr);
    url.searchParams.append('date_to', toStr);

    return await this.fetchUrl<T>(url.toString());
  };

  public getPuidById = async (
    id: number,
    { from, to }: { from: Date; to: Date },
  ): Promise<PuidInterface[]> => {
    const fromStr = strDate(from);
    const toStr = strDate(to);
    const url = new URL('/data/td', window.location.origin);
    url.searchParams.append('id', id.toString());
    url.searchParams.append('date', fromStr);
    url.searchParams.append('date_to', toStr);

    return await this.fetchUrl<PuidInterface[]>(url.toString());
  };

  public getPuidsData = async (
    ids: Array<number>,
    dates: { from: Date; to: Date },
  ): Promise<PuidInterface[][] | null> => {
    try {
      const res = await Promise.all(
        ids.map((id) => this.getPuidById(id, dates)),
      );
      return res;
    } catch (e) {
      return null;
    }
  };

  public getPuidByGanty = async (
    id: number,
    { from, to }: { from: Date; to: Date },
  ): Promise<PuidInterface[] | null> => {
    try {
      const fromStr = strDate(from);
      const toStr = strDate(to);
      const url = new URL('/data/td/gantry', window.location.origin);
      url.searchParams.append('id', id.toString());
      url.searchParams.append('date', fromStr);
      url.searchParams.append('date_to', toStr);
      return await this.fetchUrl<PuidInterface[]>(url.toString());
    } catch (e) {
      return null;
    }
  };

  public getPermisions = async <T>() => {
    const url = `/data/client/permissions`;

    try {
      const json = await this.fetchUrl<T>(url);
      return { data: json, error: null };
    } catch (e) {
      return { error: e };
    }
  };

  public getCameras = async <T>() => {
    const url = '/data/cameras';

    try {
      const json = await this.fetchUrl<T>(url);
      return { data: json, error: null };
    } catch (e) {
      return { error: e };
    }
  };

  public getReportsList = async <T = TReportItem[]>() => {
    const url = '/data/reports';

    try {
      const json = await this.fetchUrl<T>(url);
      return { reports: json, error: null };
    } catch (e) {
      return { error: e };
    }
  };

  public getReportSingle = async <T>(url: string) => {
    // See SmallRepots
    // let url = '/data/reports/single?'

    try {
      const json = await this.fetchUrl<T>(url);
      return { report: json, error: null };
    } catch (e) {
      return { error: e };
    }
  };

  public getUrl(date: Date, id: number | string, server: number): string {
    const dateString = this.dateToString(date);
    const url = new URL('/data/camera/ctrl', window.location.origin);
    url.searchParams.append('srv', server ? server.toString() : '1');
    url.searchParams.append('cam', id.toString());
    url.searchParams.append('dt_from', `${dateString} 00:00:00`);
    url.searchParams.append('dt_to', `${dateString} 23:59:59`);

    return url.toString();
  }

  dateToString(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  public async getImages(
    id: number,
    date: Date,
    server: number,
    abortController?: AbortController,
  ): Promise<TimagesData> {
    const url = this.getUrl(date, id, server);
    const data: TimagesData = {
      images: [],
      errors: [],
    };

    const imageArchive: Response = await new Promise((resolve, reject) => {
      const response = fetch(url, {
        signal: abortController ? abortController.signal : undefined,
      });
      response.then((r) => r.ok && resolve(r));
      response.catch((r) => {
        const err = `Error on recive data: Responce code ${r.status}`;
        console.error(err);
        data.errors.push(err);
        reject(data);
      });
    });

    const xml = await imageArchive.text();
    const parser = new DOMParser();
    const archives = [
      ...parser
        .parseFromString(xml, 'application/xml')
        .getElementsByTagName('archive-info'),
    ];
    function getDate(str: string): Date {
      const [date, hours] = str.split(' ');
      const [year, month, day] = date.split('-').map((e) => Number(e));
      const [hour, min, sec] = hours.split(':').map((e) => Number(e));
      return new Date(year, month - 1, day, hour, min, sec);
    }
    if (archives.length === 0) {
      data.errors.push('Данные отсутствуют');
      return data;
    } else {
      archives.forEach((a) => {
        const from = getDate(a.getAttribute('from') as string);
        const to = getDate(a.getAttribute('to') as string);
        data.images.push({ from, to });
      });
      return data;
    }
  }
}

export default new Services();
