import { MapActionTypes } from '../actions/actionTypes';
import { MapElement } from '../../components/map/Interfaces';
import { IFilter } from '../../blocks/Filter/Filter';

export type TMapState = {
  items: MapElement[];
  popUpIsActive: boolean;
  mapReady: boolean;
  filtredItems: MapElement[];
  admsChecked: boolean;
  puidChecked: boolean;
  photoChecked: boolean;
  roads: IFilter[];
  pikets: IFilter[];
  grouping: boolean;
};

const initialState: TMapState = {
  items: [] as MapElement[],
  popUpIsActive: false,
  mapReady: false,
  filtredItems: [] as MapElement[],
  admsChecked: true,
  puidChecked: true,
  photoChecked: true,
  roads: JSON.parse(localStorage.getItem('filter-map-road') || '[]'),
  pikets: [],
  grouping: true,
};

export default function mapReducer(
  state = initialState,
  action: MapActionTypes,
) {
  switch (action.type) {
    default:
      return state;
    case 'INIT_MAP_ITEMS':
      return {
        ...state,
        items: action.rawData,
        filtredItems: action.filtredData,
        mapReady: true,
      };
    case 'UPDATE_MAP_FILTER':
      return {
        ...state,
        filter: action.filter,
      };
    case 'CHANGE_MAP_CHECKBOX':
      return {
        ...state,
        ...{ [action.name]: action.value },
      };
    case 'UPDATE_MAP_FILTRED_ITEMS':
      return {
        ...state,
        filtredItems: action.filtredItems,
      };
    case 'UPDATE_MAP_PIKETS':
      return {
        ...state,
        pikets: action.pikets,
      };
    case 'UPDATE_MAP_ROADS':
      return {
        ...state,
        roads: action.roads,
      };
    case 'TOGGLE_MAP_GROUPING':
      return { ...state, grouping: !state.grouping };
  }
}
