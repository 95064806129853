import { Dispatch } from 'redux';
import Services from '../../Api/Services';
import * as T from './action-types';
import { Permissions } from './reducer';

export function getPermisions() {
  return async (dispatch: Dispatch) => {
    dispatch({ type: T.GET_PERMISIONS });

    const json = await Services.getPermisions<Permissions>();
    const { data, error } = json;

    if (error === null)
      return dispatch({ type: T.GET_PERMISIONS_SUCCESS, payload: data });
    return dispatch({ type: T.GET_PERMISIONS_ERROR, payload: error });
  };
}
