import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { getPermisions } from '../../redux/permisions/actions';
import { RootState } from '../../redux/rootReducer';
import MenuItem from './MenuItem';
import MenuUserLogin from './MenuUserLogin';

const menuObj = {
  map: 'Карта',
  meteo: 'Метеостанции',
  puid: 'ПУИДД / ТД',
  video: 'Камеры',
  reports: 'Отчёты',
  'reports-test': 'Подготовка Отчётов',
} as const;
type MenuObj = typeof menuObj;

type Mapped<T extends Object> = {
  [key in keyof T]: { name: T[key]; link: key };
};

export type TmenuElement = Mapped<MenuObj>[keyof MenuObj] & { type?: 'admin' };

export type Tmenu = TmenuElement[];

export function getMenu(
  permissions: RootState['permissions']['permissions'],
): Tmenu {
  const result: Tmenu = [];
  result.push({ name: menuObj.map, link: 'map' });

  const reports: Tmenu[number] = { name: menuObj.reports, link: 'reports' };

  if (!permissions) {
    result.push(reports);
    return result;
  } else {
    const isWS = 'ws.allowed' in permissions && permissions['ws.allowed'];
    const isTD = 'td.allowed' in permissions && permissions['td.allowed'];
    const isCam =
      'camera.allowed' in permissions && permissions['camera.allowed'];
    const isReport =
      'report.allowed' in permissions && permissions['report.allowed'];

    isWS && result.push({ name: menuObj.meteo, link: 'meteo' });
    isTD && result.push({ name: menuObj.puid, link: 'puid' });
    isCam && result.push({ name: menuObj.video, link: 'video' });
    isReport && result.push(reports);

    result.push({
      name: 'Подготовка Отчётов',
      link: 'reports-test',
      type: 'admin',
    });
  }
  return result;
}

function Menu() {
  const dispatch = useDispatch();
  const { isLoaded, isLoading, error, permissions } = useSelector(
    (selector: RootState) => selector.permissions,
  );
  const menu = getMenu(permissions);

  useEffect(() => {
    !isLoaded && !isLoading && dispatch(getPermisions());
  });

  return (
    <Nav>
      <List>
        {menu.map((el) => (
          <MenuItem key={el.name} data={el} />
        ))}
      </List>
      {error !== null && (
        <ErrorSpan>Ошибка получения данных: {error.message}</ErrorSpan>
      )}
      <MenuUserLogin />
    </Nav>
  );
}

export default Menu;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  background: #fff;
  z-index: 3;
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 0 20px;
  height: 39px;
  box-sizing: border-box;
  border-bottom: 1px solid #c4c4c4;

  @media only screen and (max-device-width: 1000px) {
    nav {
      padding: 10px 0 0;
    }
  }
`;

const List = styled.ul`
  display: flex;
  overflow: auto;
  height: 100%;
  align-items: cneter;
`;
const ErrorSpan = styled.span`
  color: red;
`;
