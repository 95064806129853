import { FilterActcionTypes } from '../actions/actionTypes';

export type TFilterPiket = {
  id_gantry: number;
  track: string;
  km: string;
};

export type TFilterRoad = {
  id_road: number;
  description: string;
  code: string;
  pikets: TFilterPiket[];
};

export type TFilterData = {
  [key: string]: TFilterRoad;
};

export type TFilterInitial = { isLoaded: boolean; data: TFilterData };

const initialState: TFilterInitial = {
  isLoaded: false,
  data: {},
};

export default function filterReducer(
  state = initialState,
  action: FilterActcionTypes,
) {
  switch (action.type) {
    default:
      return state;
    case 'FILTER_INIT':
      return { ...state };
    case 'SET_FILTER':
      return { ...state, ...action.data };
  }
}
