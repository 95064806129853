import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/rootReducer';
import styled from 'styled-components';
import Auth from '../../blocks/Auth/Auth';
import { verify, logout } from '../../redux/actions/auth';

const MenuUserLogin = () => {
  const {
    authorized: auth,
    name,
    isAdmin,
  } = useSelector((s: RootState) => s).auth;
  const [isAuthActive, setIsAuthActive] = useState(false);

  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    const current = ref.current as HTMLElement;
    if (current && !current.contains(event.target as HTMLElement)) {
      setIsAuthActive(false);
    }
  };

  useEffect(() => {
    dispatch(verify());

    document.addEventListener('mousedown', handleClickOutside, true);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    setIsAuthActive(false);
    dispatch(logout());
  };

  return (
    <LoginWrapper ref={ref}>
      {auth ? (
        <>
          <Name>{name}</Name>
          {!isAdmin && <Button onClick={handleLogout}>Выход</Button>}
        </>
      ) : (
        !isAdmin && (
          <Button onClick={() => setIsAuthActive(!isAuthActive)}>
            {!isAuthActive ? 'Вход' : 'Закрыть'}
          </Button>
        )
      )}
      {auth && isAdmin && (
        <Button onClick={() => (window.location.href = '/admin/logout')}>
          Выход
        </Button>
      )}
      {!auth && isAuthActive && <Auth />}
    </LoginWrapper>
  );
};

export default MenuUserLogin;

const LoginWrapper = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  padding-left: 10px;
`;

const Button = styled.button`
  padding: 5px 10px;
  outline: none;
  background: transparent;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  cursor: pointer;
  &:active {
    outline: none;
  }

  &:hover {
    border: 1px solid gray;
  }
`;

const Name = styled.div`
  margin: 0 10px;
  color: #444;
  @media (max-width: 650px) {
    display: none;
  }

  &:first-letter {
    text-transform: uppercase;
  }
`;
