import React, { Suspense, lazy } from 'react';
import './Content.css';

import { Route, Navigate, Routes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';

const Map = lazy(() => import('./map/Map'));
//const Table = lazy(() => import('./table/Table.js'));
const Meteo = lazy(() => import('./meteo/Meteo'));
const Puids = lazy(() => import('./puid/Puids'));
const Reports = lazy(() => import('./reports/Reports'));
const Cameras = lazy(() => import('./cameras/Cameras'));
const ReportsTest = lazy(() => import('./reports-test/ReportsTest'));

function Content() {
  const { isLoaded, permissions } = useSelector(
    (selector: RootState) => selector.permissions,
  );
  let isWS;
  let isTD;
  let isCam;
  let isReport;

  if (isLoaded && permissions) {
    isWS = 'ws.allowed' in permissions && permissions['ws.allowed'];
    isTD = 'td.allowed' in permissions && permissions['td.allowed'];
    isCam = 'camera.allowed' in permissions && permissions['camera.allowed'];
    isReport = 'report.allowed' in permissions && permissions['report.allowed'];
  }

  return (
    <div className="content">
      <Suspense fallback={<div>Загрузка...</div>}>
        <Routes>
          <Route path="/" element={<Navigate to="/map/" />} />
          <Route path="/map/" element={<Map />} />
          <Route path="/meteo/" element={isWS ? <Meteo /> : <Deny />} />
          <Route path="/puid/" element={isTD ? <Puids /> : <Deny />} />
          <Route path="/video/" element={isCam ? <Cameras /> : <Deny />} />
          <Route path="/reports/" element={isReport ? <Reports /> : <Deny />} />
          <Route path="/reports-test/" element={<ReportsTest/>} />
        </Routes>
      </Suspense>
    </div>
  );
}

export function Deny() {
  return <div>Доступ Запрещен</div>;
}
export default Content;
