import {
  AUTHORIZE,
  SET_ERROR,
  UNAUTHORIZE,
  SET_READY,
  Authorize,
  LOGOUT,
} from './actionTypes';

export function authorization(login: string, password: string) {
  return async (dispatch: Function) => {
    const url = '/client/password/login';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ login: login, password: password }),
    });
    if (response.status === 200) {
      await response.json();
      dispatch(verify());
      window.location.reload();
    }

    if (response.status === 401) {
      dispatch(setError('Неправльный логин или пароль'));
      dispatch(ready());
    }
  };
}

export function verify() {
  return async (dispatch: Function) => {
    const url = '/data/client/info';
    const response = await fetch(url);

    if (response.status !== 200) {
      console.error('unexpected error occured');
      return;
    }

    const { email: user, authorized, admin } = await response.json();
    //const role = admin ? 'admin' : '';
    //
    const role = '';
    const isAdmin = admin as boolean;

    if (authorized) {
      dispatch(updateAuthorize({ user, role }, true, isAdmin));
    } else {
      dispatch(updateAuthorize({ user: '', role }, false, isAdmin));
    }

    dispatch(ready());
  };
}

export function logout() {
  return async (dispatch: Function) => {
    const url = '/client/logout';
    const response = await fetch(url);
    if (response.status === 200) {
      dispatch(() => {
        return {
          type: LOGOUT,
        };
      });
      dispatch(verify());
      window.location.reload();
    }
  };
}
export function setError(error: string) {
  return {
    type: SET_ERROR,
    error,
  };
}

export function updateAuthorize(
  data: Authorize['data'],
  value: boolean,
  isAdmin?: boolean,
): Authorize {
  return {
    type: AUTHORIZE,
    data,
    value,
    isAdmin,
  };
}

export function unauthorize() {
  return {
    type: UNAUTHORIZE,
  };
}

export function ready() {
  return { type: SET_READY };
}
