import styled from 'styled-components';

export const Authorize = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-width: 320px;
  background: white;
  border: 1px solid gray;
  border-radius: 5px;
  box-sizing: border-box;
`;
export const Label = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const Input = styled.input`
  width: 100%;
  padding: 10px;
  border: 1px solid gray;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;
`;
export const Title = styled.span``;

export const Button = styled.button`
  margin-top: 20px;
  font-weight: bold;
  border: 1px solid gray;
  padding: 10px;
  width: 100%;
  &:hover {
    background: white;
  }
  &:active {
    background: green;
    color: white;
  }
`;

export const Error = styled.div`
  width: 100%;
  padding: 10px;
  color: red;
`
