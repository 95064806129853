import * as T from './action-types';

type PermitionsState = {
  permissions: null | Permissions;
  isLoaded: boolean;
  isLoading: boolean;
  error: null | Error;
};

const initialState: PermitionsState = {
  permissions: null,
  isLoaded: false,
  isLoading: false,
  error: null,
};

export default function permisionsReducer(
  state = initialState,
  action: { type: keyof typeof T; payload: any },
): PermitionsState {
  switch (action.type) {
    default:
      return state;

    case 'GET_PERMISIONS': {
      return {
        ...state,
        isLoading: true,
      };
    }

    case 'GET_PERMISIONS_SUCCESS': {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        permissions: action.payload,
      };
    }

    case 'GET_PERMISIONS_ERROR': {
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        error: action.payload,
      };
    }
  }
}

export type Permissions = {
  'atm.air_pressure': boolean;
  'atm.air_temperature': boolean;
  'atm.allowed'?: boolean;
  'atm.melting_point': boolean;
  'atm.precipitation_intensity': boolean;
  'atm.precipitation_type': boolean;
  'atm.relative_air_humidity': boolean;
  'atm.snow_height': boolean;
  'atm.temperature_in_depth': boolean;
  'atm.visibility': boolean;
  'atm.wind_direction': boolean;
  'atm.wind_speed_average': boolean;
  'atm.wind_speed_peak': boolean;
  'camera.allowed'?: boolean;
  'camera.comment': boolean;
  'camera.conn': boolean;
  'camera.descent': boolean;
  'camera.descr': boolean;
  'camera.designation': boolean;
  'camera.dir': boolean;
  'camera.gantry_id': boolean;
  'camera.gantry_name': boolean;
  'camera.id': boolean;
  'camera.label': boolean;
  'camera.lat': boolean;
  'camera.loc': boolean;
  'camera.long': boolean;
  'camera.name': boolean;
  'camera.pair': boolean;
  'camera.road_id': boolean;
  'camera.segment_descr': boolean;
  'camera.server': boolean;
  'camera.stream': boolean;
  'map.camera': boolean;
  'map.descr': boolean;
  'map.designation': boolean;
  'map.gantry_id': boolean;
  'map.label': boolean;
  'map.lat': boolean;
  'map.long': boolean;
  'map.name': boolean;
  'map.road_id': boolean;
  'map.state': boolean;
  'map.td': boolean;
  'map.ws': boolean;
  'report.29-puid': boolean;
  'report.39-meteo': boolean;
  'report.40-meteo': boolean;
  'report.41-meteo': boolean;
  'report.50-reports-puid': boolean;
  'report.51-reports-puid': boolean;
  'report.54-reports-puid': boolean;
  'report.57-reports-puid': boolean;
  'report.58-reports-puid': boolean;
  'report.59-reports-puid': boolean;
  'report.60-reports-puid': boolean;
  'report.61-reports-puid': boolean;
  'report.62-reports-puid': boolean;
  'road.1': boolean;
  'road.10': boolean;
  'road.11': boolean;
  'road.12': boolean;
  'road.13': boolean;
  'road.14': boolean;
  'road.15': boolean;
  'road.16': boolean;
  'road.17': boolean;
  'road.2': boolean;
  'road.3': boolean;
  'road.4': boolean;
  'road.5': boolean;
  'road.6': boolean;
  'road.7': boolean;
  'road.8': boolean;
  'road.9': boolean;
  'rs.allowed'?: boolean;
  'rs.concentration_residual_salt': boolean;
  'rs.freezing_temperature': boolean;
  'rs.quantity_residual_salt': boolean;
  'rs.road_surface_condition': boolean;
  'rs.road_surface_temperature': boolean;
  'rs.temperature_in_depth_1': boolean;
  'rs.temperature_in_depth_6sm': boolean;
  'rs.water_film_thickness': boolean;
  'td.allowed'?: boolean;
  'td.designation': boolean;
  'td.details': boolean;
  'td.dir': boolean;
  'td.id': boolean;
  'td.label': boolean;
  'td.lane_num': boolean;
  'td.minmax': boolean;
  'td.name': boolean;
  'td.short': boolean;
  'td.state': boolean;
  'ws.allowed'?: boolean;
  'ws.comment': boolean;
  'ws.description': boolean;
  'ws.id': boolean;
  'ws.label': boolean;
  'ws.minmax': boolean;
  'ws.name': boolean;
  'ws.state': boolean;
  'camera.period_1h'?: boolean;
  'camera.period_1m'?: boolean;
  'camera.period_1s'?: boolean;
  'report.allowed'?: boolean;
  'camera.timeline'?: boolean;
  'camera.time_input'?: boolean;
};
