import { TFilterData } from '../reducers/filter';
import { IFilter } from '../../blocks/Filter/Filter';

//meteo
export const FETCH_METEO_START = 'FETCH_METEO_START';
export const FETCH_METEO_SUCCESS = 'FETCH_METEO_SUCCESS';
export const FETCH_METEO_ERROR = 'FETCH_METEO_ERROR';
export const CHANGE_FILTER = 'CHANGE_FILTER';
export const UPDATE_FILTRED_ITEMS = 'UPDATE_FILTRED_ITEMS';

//map
export const INIT_MAP_ITEMS = 'INIT_MAP_ITEMS';
export const UPDATE_MAP_FILTRED_ITEMS = 'UPDATE_MAP_FILTRED_ITEMS';
export const UPDATE_MAP_FILTER = 'UPDATE_MAP_FILTER';
export const UPDATE_MAP_ROADS = 'UPDATE_MAP_ROADS';
export const UPDATE_MAP_PIKETS = 'UPDATE_MAP_PIKETS';
export const CHANGE_MAP_CHECKBOX = 'CHANGE_MAP_CHECKBOX';
export const TOGGLE_MAP_GROUPING = 'TOGGLE_MAP_GROUPING';

interface InitMapItems {
  type: typeof INIT_MAP_ITEMS;
  rawData: any[];
  filtredData: any[];
}

interface UpdateMapFilter {
  type: typeof UPDATE_MAP_FILTER;
  filter: string;
}

interface ChangeMapCheckbox {
  type: typeof CHANGE_MAP_CHECKBOX;
  name: string;
  value: boolean;
}

interface UpdatemapFiltredItems {
  type: typeof UPDATE_MAP_FILTRED_ITEMS;
  filtredItems: any[];
}
interface ChangeMapRoads {
  type: typeof UPDATE_MAP_ROADS;
  roads: IFilter[];
}
interface ChangeMapPikets {
  type: typeof UPDATE_MAP_PIKETS;
  pikets: IFilter[];
}
interface ToggleMapGroupig {
  type: typeof TOGGLE_MAP_GROUPING;
}

export type MapActionTypes =
  | InitMapItems
  | UpdateMapFilter
  | ChangeMapCheckbox
  | UpdatemapFiltredItems
  | ChangeMapRoads
  | ChangeMapPikets
  | ToggleMapGroupig;

//auth
export const AUTHORIZE = 'AUTHORIZE';
export const UNAUTHORIZE = 'UNAUTHORIZE';
export const SET_ERROR = 'SET_ERROR';
export const SET_READY = 'SET_READY';
export const LOGOUT = 'LOGOUT';

type AuthorizeData = { user: string; role: string | null };

export interface Authorize {
  type: typeof AUTHORIZE;
  value: boolean;
  data: AuthorizeData;
  isAdmin?: boolean;
}

interface Unauthorize {
  type: typeof UNAUTHORIZE;
}

interface SetError {
  type: typeof SET_ERROR;
  error: string;
}

interface SetReady {
  type: typeof SET_READY;
  error: string;
}
interface Logout {
  type: typeof LOGOUT;
}

export type AuthActionTypes =
  | Authorize
  | Unauthorize
  | SetError
  | SetReady
  | Logout;

//reports
export const SET_REPORTS = 'SET_REPORTS';

interface SetReports {
  type: typeof SET_REPORTS;
  data: any[];
}

export type ReportsActionTypes = SetReports;

export type AuthType = {
  authorized: boolean;
  error: string;
  name: string | undefined;
  role: string | undefined;
  ready: boolean;
  isAdmin:boolean
};

//filters
export const FILTER_INIT = 'FILTER_INIT';
export const SET_FILTER = 'SET_FILTER';

interface FilterInit {
  type: typeof FILTER_INIT;
  data: any[];
}
interface SetFilter {
  type: typeof SET_FILTER;
  data: TFilterData;
}

export type FilterActcionTypes = FilterInit | SetFilter;
