export function strDate(date: Date) {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
}

export function getStartDate(date: Date, type?: 'month' | 'year') {
  const d = new Date(date.getTime());
  if (type === 'month' || type === 'year') {
    d.setDate(1);
  }
  d.setHours(0);
  d.setMinutes(0);
  d.setSeconds(0);
  type === 'year' && d.setMonth(0);
  return d;
}
export function getEndDate(date: Date, type?: 'month' | 'year') {
  const d = new Date(date.getTime());
  d.setHours(23);
  d.setMinutes(59);
  d.setSeconds(59);

  if (type === 'year') {
    d.setMonth(0);
    d.setFullYear(d.getFullYear() + 1);
    d.setDate(0);
  }
  if (type === 'month') {
    d.setMonth(d.getMonth() + 1);
    d.setDate(0);
  }
  return d;
}
