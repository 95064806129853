import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { TmenuElement } from './Menu';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/rootReducer';

const MenuItem = (props: { data: TmenuElement }) => {
  const { link, name, type } = props.data;
  const { isAdmin } = useSelector((s: RootState) => s.auth);
  const allow = type === undefined || isAdmin;

  const { pathname } = useLocation();
  const [active, setActive] = useState(pathname === `/${link}/`);

  useEffect(() => {
    setActive(pathname === `/${link}/`);
  }, [link, pathname]);

  return allow ? (
    <Item active={active}>
      {active ? (
        <Active>{name}</Active>
      ) : (
        <NavLink to={`/${link}/`}>{name}</NavLink>
      )}
    </Item>
  ) : null;
};

export default MenuItem;

type Tstyled = {
  active: boolean;
};

const Item = styled.li<Tstyled>`
  font-family: Roboto;
  color: #444;
  margin: 0 15px;
  list-style: none;
  ${(p) => (p.active ? 'font-weight: bold;' : null)}
  height: 100%;

  &:hover {
    color: #000;
  }

  & a {
    user-select: none;
    display: flex;
    align-items: center;
    color: inherit;
    white-space: nowrap;
    text-decoration: none;
    height: 100%;
  }
`;

const Active = styled.span`
  user-select: none;
  font-weight: bold;
  box-sizing: border-box;
  height: 100%;
  border-top: 3px solid transparent;
  border-bottom: 3px solid #f52e2e;
  font-family: Roboto;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;
