import { fetchUrl } from '../../components/utils/fetch';
import { TFilterData } from '../reducers/filter';
import { FILTER_INIT, SET_FILTER } from './actionTypes';

export function initFilter() {
  return async (dispatch: Function) => {
    dispatch(init());
    const url = '/data/roads';
    const result = await fetchUrl<TFilterData>(url);
    dispatch(setFilter(result));
  };
}
export function init() {
  return { type: FILTER_INIT };
}

export function setFilter(data: TFilterData) {
  return { type: SET_FILTER, data: { data, isLoaded: true } };
}
