import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Authorize, Label, Input, Title, Button, Error } from './Auth.style';
import { verify, authorization, setError } from '../../redux/actions/auth';
import Preloader from '../Preloader/Preloader';
import { RootState } from '../../redux/rootReducer';

type TAuthProps = {
  children?: JSX.Element;
  roles?: string[];
};
/**
 * Обёртка  для авторизации
 */
export default function Auth(props: TAuthProps) {
  const { children } = props;
  const dispatch = useDispatch();
  const { name, error, ready, isAdmin } = useSelector((s: RootState) => s.auth);
  const [authorized, setAuthorized] = useState<boolean>(isAdmin);
  const [login, setLogin] = useState<string>(name || '');
  const [password, setPassword] = useState<string>('');

  useEffect(() => {
    !isAdmin && dispatch(setError('Недостаточно прав'));
    setAuthorized(isAdmin);
  }, [dispatch, isAdmin]);

  useEffect(() => {
    dispatch(verify());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    !authorized
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'unset');
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [authorized]);

  const handleClick = async (e?: React.FormEvent<HTMLFormElement>) => {
    if (e !== undefined) e.preventDefault();
    dispatch(authorization(login, password));
  };

  return ready ? (
    authorized ? (
      <>{children}</>
    ) : (
      <Authorize>
        {error ? <Error>{error}</Error> : null}
        <form onSubmit={handleClick}>
          <Label>
            <Title>Логин:</Title>
            <Input
              autoFocus
              name="name"
              type="text"
              value={login}
              onChange={(e) => setLogin(e.target.value)}
            />
          </Label>
          <Label>
            <Title>Пароль:</Title>
            <Input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleClick()}
            />
          </Label>
          <Button type="submit">Отправить</Button>
        </form>
      </Authorize>
    )
  ) : (
    <Preloader />
  );
}
