import { ReportsActionTypes } from '../actions/actionTypes';

export type TReport = {
  id: number;
  name: string;
  postgresql: string;
  active: boolean;
  sort: number;
  file: Blob | null;
  filename: string;
  type: 'meteo' | 'puid' | 'reports-meteo' | 'reports-puid';
};

export type TReports = {
  data: TReport[];
};
const initialState: TReports = {
  data: [],
};

export default function reportsReducer(
  state = initialState,
  action: ReportsActionTypes,
) {
  switch (action.type) {
    default:
      return state;
    case 'SET_REPORTS':
      return {
        ...state,
        data: action.data,
      };
  }
}
