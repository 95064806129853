import styled from 'styled-components';

export const Foot = styled.footer`
  background: #3b72a9;
  min-height: 40px;
  color: #fff;
  padding: 0 10px;
  overflow: auto;
  display: flex;
  align-items: center;
`;

export const ImageBis = styled.img`
  width: auto;
  height: 40px;
`;

export const Bis = styled.a`
  text-decoration: none;
  color: white;
  font-size: 10px;
  margin-right: 5px;
  &:hover {
    text-decoration: underline;
  }
`;

export const MailTo = styled.a`
  color: #fff;
  margin-left: 20px;
  font-size: 12px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const Info = styled.p`
  width: 300px;
  height: 100%;
  margin: 6px 20px 0 auto;
  font-size: 10px;
  text-align: right;
  vertical-align: middle;
`;
