import autodorLogo from './rosautodor.png';
import proff from './prof.svg';
import proffMobile from './prof-mobile.svg';
import { useState, useEffect } from 'react';

import { Head, Autodor, Image, Prof } from './Header.style';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function Header() {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions(),
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Head>
      <Autodor
        href="https://sevzap.rosavtodor.ru/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <Image src={autodorLogo} alt="Росавтодор" />
      </Autodor>
      {/*
       *<div className="call-me">
       *  <p></p>
       *</div>
       */}
      <Prof href="http://www.profingenerstroy.ru/">
        {windowDimensions.width > 700 ? (
          <Image src={proff} alt="ПрофИнженерСтрой" />
        ) : (
          <Image src={proffMobile} alt="ПрофИнженерСтрой" />
        )}
      </Prof>
    </Head>
  );
}
export default Header;
