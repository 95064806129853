import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Header from './components/Header';
import Menu from './components/menu/Menu';
import Content from './components/Content';
import Footer from './components/Footer';
import { BrowserRouter } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { YMInitializer } from 'react-yandex-metrika';
import { initFilter } from './redux/actions/filter';

export const history = createBrowserHistory();

export default function App() {
  const isDevelopment = process.env.NODE_ENV === 'development';
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initFilter());
  }, [dispatch]);

  return (
    <div className="container">
      {!isDevelopment ? (
        <div>
          <YMInitializer
            accounts={[70860634]}
            options={{ webvisor: true }}
            version="2"
          />
        </div>
      ) : null}
      <BrowserRouter>
        <Header />
        <Menu />
        <Content />
        <Footer />
      </BrowserRouter>
    </div>
  );
}
