import { combineReducers } from 'redux';
import mapReducer from './reducers/map';
import authReducer from './reducers/auth';
import reportsReducer from './reducers/reports';
import filterReducer from './reducers/filter';
import permisionsReducer from './permisions/reducer';

export const rootReducer = combineReducers({
  map: mapReducer,
  auth: authReducer,
  reports: reportsReducer,
  filter: filterReducer,
  permissions: permisionsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
