//import bis from './bis.png';
import { Foot, MailTo, Info, Bis } from './Footer.style';

export default function Footer() {
  return (
    <Foot>
      <Bis href="https://bis.spb.ru/">
        Разработано:
        <br /> Бюро Интеллектуальных Систем
      </Bis>
      <MailTo href="mailto:asudd-sz@profingenerstroy.ru">
        asudd-sz@profingenerstroy.ru
      </MailTo>
      {/* <div className="counter">{seconds}</div>*/}
      <Info>
        Ресурс носит информационный характер. Администрация не несет
        ответственности за действия третьих лиц.
      </Info>
    </Foot>
  );
}
