let alertMessage = 'Возникла ошибка, повторите запрос позднее';

export async function fetchUrl<T>(url: string): Promise<T> {
  const response = await fetch(url);
  if (response.ok) {
    const json = await response.json();
    return json;
  } else {
    throw new Error('error');
  }
}

export async function fetchData(urlData: string, urlData2: string) {
  return fetch(urlData)
    .then((res) => (res.ok ? res : Promise.reject(res)))
    .then(() =>
      fetch(urlData2)
        .then((response) => (response.ok ? response : Promise.reject(response)))
        .then((response) => response.json())
        .then((response) => {
          return response;
        })
        .catch(() => alert(alertMessage)),
    )
    .catch(() => alert(alertMessage));
}

export async function fetchTraceData(urlData: string) {
  return fetch(urlData)
    .then((response) => response.json())
    .then((response) => {
      return response;
    });
}
