import { AuthActionTypes, AuthType } from '../actions/actionTypes';

const initialState: AuthType = {
  authorized: false,
  error: '',
  name: undefined,
  ready: false,
  role: undefined,
  isAdmin: false,
};

export default function authReducer(
  state = initialState,
  action: AuthActionTypes,
) {
  switch (action.type) {
    default:
      return state;
    case 'AUTHORIZE':
      return {
        ...state,
        authorized: action.value,
        name: action.data.user,
        role: action.data.role,
        isAdmin: action.isAdmin,
        error: '',
      };
    case 'UNAUTHORIZE':
      return {
        ...state,
        authorized: false,
        name: '',
        error: '',
      };
    case 'SET_ERROR': {
      return {
        ...state,
        error: action.error,
      };
    }
    case 'SET_READY': {
      return { ...state, ready: true };
    }
    case 'LOGOUT': {
      return {
        ...state,
        authorized: false,
        error: '',
        name: undefined,
        ready: false,
        role: undefined,
      };
    }
  }
}
