import styled from 'styled-components';

export const Head = styled.header`
  font-family: Montserrat;
  display: flex;
  padding: 10px 20px 10px;
  border-bottom: 1px solid #c4c4c4;
  justify-content: space-between;
  align-items: center;
`;

export const Autodor = styled.a`
  color: #1d71b8;
  font-style: italic;
  text-transform: uppercase;
  max-width: 320px;
`;

export const Prof = styled.a`
  max-width: 300px;
  margin-left: 10px;
`;
export const Image = styled.img`
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
`;
